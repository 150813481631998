import {
  getDataFromStore,
  loadResponseIntoStore,
} from "../../utils/helpers/dynamicActionHelper";
import { cloneDeep } from "lodash";
export const AGGREGATION_STRATERGY = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "kpi-master-list",
        staticProps: {
          customStyle: {
            display: "flex",
            justifyContent: "space-between",
            //marginTop: "8px",
            padding: '10px 10px 0px 10px'
          },
        },
        componentProps: {
          content: [
            {
              type: "SelectedHierarchy",
            },
            {
              type: "div",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  width: "85px",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "channel-remove-button",
                    type: "iconButton",
                    staticProps: {
                      variant: "outlined",
                      icon: "search",
                      // iconClass: "colorBlack",
                      // className: "icon-button",
                      customStyle: {
                        background: "white",
                        color: "#0055AF",
                        border: "1px solid #0055AF",
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "custom",
                            function: (props) => {
                              //console.log("props", props);
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    id: "channel-remove-button",
                    type: "iconButton",
                    staticProps: {
                      variant: "outlined",
                      icon: "publish",
                      // iconClass: "colorBlack",
                      // // className: "icon-button",
                      customStyle: {
                        background: "white",
                        color: "#0055AF",
                        border: "1px solid #0055AF",
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "custom",
                            function: (props) => {
                              const uploadFlag = getDataFromStore(
                                "reducer",
                                "uploadFlag"
                              );
                              const updatedFlag =
                                uploadFlag === undefined ? true : !uploadFlag;
                              loadResponseIntoStore(
                                [
                                  {
                                    destination: "reducer",
                                    dataKey: "uploadFlag",
                                    overwrite: true,
                                  },
                                ],
                                updatedFlag
                              );
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "screenName",
                    value: "AGGREGATION_STRATEGY",
                  },
                ],
              },
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 65,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "reducer",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    sourceId: "newConfig",
                    subjectId: "configDetails",
                    subjectidNested: "attributeId",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "kpiConfigurationList",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "FileSelector",
        id: "aggregation-file-selector",
        staticProps: {
          display: {
            type: "controlled",
            default: {
              show: true,
              enabled: true,
            },
          },
          handleCancelAllUploads: () => {
            loadResponseIntoStore(
              [
                {
                  destination: "reducer",
                  dataKey: "uploadFlag",
                  overwrite: true,
                },
              ],
              false
            );
          },
          handleAllFiles: (uploadedFileData) => {
            const data = cloneDeep(
              getDataFromStore("reducer", "kpiConfigurationList")
            );
            uploadedFileData.forEach((element) => {
              data.unshift(element);
            })
            
            loadResponseIntoStore(
              [
                {
                  destination: "reducer",
                  dataKey: "kpiConfigurationList",
                  overwrite: true,
                },
              ],
              data
            );
            loadResponseIntoStore(
              [
                {
                  destination: "reducer",
                  dataKey: "uploadFlag",
                  overwrite: true,
                },
              ],
              false
            );
          },
          uniqueId: 'kpi_id',
          columnKeys: [
            { header: "KPI", key: "name" },
            { header: "Label", key: "label" },
            { header: "Product Hierarchy", key: "roll_up_product_hierarchy" },
            { header: "Channel Aggregation", key: "roll_up_channel" },
            { header: "Monthly Totals", key: "roll_up_timeline" },
            { header: "Quarterly Totals", key: "roll_up_timeline" },
            { header: "Year To Date Totals", key: "roll_up_timeline" },
          ],
        },
      },
      {
        type: "div",
        id: "workbench-metrics-table",
        staticProps: {
          className: "common-content-container",
          customStyle: {
            //marginTop: "20px",
            padding: '0 10px'
          },
        },
        componentProps: {
          content: [
            {
              type: "aggrid",
              staticProps: {
                height: "500px",
                width: "100%",
                tableId: "decision_dashboard_strategies_product_count",
                rowSelection: "multiple",
                onClickCell: "navigate",
                uniqueKey: "kpi_id",
                floatingFilter: true,
                tableConfig: {
                  column_headers: [
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "",
                      key: "",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: true,
                      lockPinned: true,
                      checkbox_selection: true,
                      row_group: false,
                      suppressMenu: true,
                      maxWidth: 50,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Details",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "KPI",
                          key: "name",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          pinned: true,
                          row_group: false,
                          filter: true,
                          cellRenderer: "customIconClick",
                          cellRendererParams: {
                            actions: [
                              {
                                type: "reducer_function",
                                responseFormatter: [
                                  {
                                    destination: "reducer",
                                    dataKey: "loadEditAggregationStrategy",
                                    value: true,
                                  },
                                ],
                              },
                              {
                                type: "redirect",
                                link: "add-new-config",
                              },
                              {
                                type: "reducer_function",
                                params: [
                                  {
                                    source: "self",
                                    apiRequestKey: "selectedKpi",
                                    dataType: "basic",
                                  },
                                ],
                                responseFormatter: [
                                  {
                                    destination: "reducer",
                                    dataKey: "selectedKpi",
                                    apiResponseKey: "selectedKpi",
                                    dataType: "basic",
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Label",
                          key: "label",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          pinned: true,
                          row_group: false,
                          filter: true,
                        },
                        // {
                        //   type: "static_column",
                        //   mapping: "data",
                        //   header: "KPI Identifer/Key",
                        //   key: "label",
                        //   action: null,
                        //   hidden: false,
                        //   aggregate: null,
                        //   sorting: false,
                        //   filter: false,
                        //   pinned: true,
                        //   row_group: false,
                        // },
                        // {
                        //   type: "static_column",
                        //   mapping: "data",
                        //   header: "Versions",
                        //   key: "kpi_key",
                        //   action: null,
                        //   hidden: false,
                        //   aggregate: null,
                        //   sorting: false,
                        //   filter: false,
                        //   pinned: true,
                        //   row_group: false,
                        // },
                      ],
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Product Hierarchy",
                      key: "roll_up_product_hierarchy",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: false,
                      checkbox_selection: false,
                      row_group: false,
                      minWidth: 350,
                      wrapText: true,
                      cellRenderer: "CustomTextField",
                      cellRendererParams: {
                        field: "roll_up_product_hierarchy",
                        handleChange: (params) => {
                          const data = cloneDeep(
                            getDataFromStore("reducer", "kpiConfigurationList")
                          );
                          data[params.rowIndex] = params.data;
                          loadResponseIntoStore(
                            [
                              {
                                destination: "reducer",
                                dataKey: "kpiConfigurationList",
                                overwrite: true,
                              },
                            ],
                            data
                          );
                        },
                      },
                      //filter: true,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Channel Aggregation",
                      key: "roll_up_channel",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: false,
                      checkbox_selection: false,
                      row_group: false,
                      minWidth: 300,
                      wrapText: true,
                      //filter: true,
                      cellRenderer: "CustomTextField",
                      cellRendererParams: {
                        field: "roll_up_channel",
                        handleChange: (params) => {
                          const data = cloneDeep(
                            getDataFromStore("reducer", "kpiConfigurationList")
                          );
                          data[params.rowIndex] = params.data;
                          loadResponseIntoStore(
                            [
                              {
                                destination: "reducer",
                                dataKey: "kpiConfigurationList",
                                overwrite: true,
                              },
                            ],
                            data
                          );
                        },
                      },
                    },

                    // {
                    //   type: "static_column",
                    //   mapping: "data",
                    //   header: "Bucket",
                    //   key: "total_bucket_aggregation_formula",
                    //   action: "",
                    //   hidden: false,
                    //   aggregate: null,
                    //   sorting: false,
                    //   filter: false,
                    //   pinned: false,
                    //   checkbox_selection: false,
                    //   row_group: false,
                    // },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Time Period Rule",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Monthly Totals",
                          key: "roll_up_timeline",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                          minWidth: 300,
                          wrapText: true,
                          //filter: true,
                          cellRenderer: "CustomTextField",
                          cellRendererParams: {
                            field: "roll_up_timeline",
                            handleChange: (params) => {
                              const data = cloneDeep(
                                getDataFromStore(
                                  "reducer",
                                  "kpiConfigurationList"
                                )
                              );
                              data[params.rowIndex] = params.data;
                              loadResponseIntoStore(
                                [
                                  {
                                    destination: "reducer",
                                    dataKey: "kpiConfigurationList",
                                    overwrite: true,
                                  },
                                ],
                                data
                              );
                            },
                          },
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Quarterly Totals",
                          key: "roll_up_timeline",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                          minWidth: 300,
                          wrapText: true,
                          //filter: true,
                          cellRenderer: "CustomTextField",
                          cellRendererParams: {
                            field: "roll_up_timeline",
                            handleChange: (params) => {
                              const data = cloneDeep(
                                getDataFromStore(
                                  "reducer",
                                  "kpiConfigurationList"
                                )
                              );
                              data[params.rowIndex] = params.data;
                              loadResponseIntoStore(
                                [
                                  {
                                    destination: "reducer",
                                    dataKey: "kpiConfigurationList",
                                    overwrite: true,
                                  },
                                ],
                                data
                              );
                            },
                          },
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Year To Date Totals",
                          key: "roll_up_timeline",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                          minWidth: 300,
                          wrapText: true,
                          cellRenderer: "CustomTextField",
                          cellRendererParams: {
                            field: "roll_up_timeline",
                            handleChange: (params) => {
                              const data = cloneDeep(
                                getDataFromStore(
                                  "reducer",
                                  "kpiConfigurationList"
                                )
                              );
                              data[params.rowIndex] = params.data;
                              loadResponseIntoStore(
                                [
                                  {
                                    destination: "reducer",
                                    dataKey: "kpiConfigurationList",
                                    overwrite: true,
                                  },
                                ],
                                data
                              );
                            },
                          },
                          //filter: true,
                        },
                      ],
                    },
                  ],
                },
              },
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "kpiConfigurationList",
                  // subjectId: "data",
                  // subjectidNested: "data",
                  //subjectId: "0",
                  subjectidNested: "data",
                  dataType: "array",
                },
              },
            },
          ],
        },
      },
    ],
  },
};
