import { getDataFromStore, loadResponseIntoStore } from "../../utils/helpers/dynamicActionHelper";

export const EDIT_KPI = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            margin: "2px 10px",
            //height: "600px",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "enable-disable-div",
              staticProps: {
                customStyle: {
                  display: "flex",
                  marginBottom: "10px",
                  marginTop: "10px",
                  width: "100%",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "enable-disable-text",
                    staticProps: {
                      margin: "20px 0px",
                    },
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          staticProps: {
                            variant: "h3",
                            content: "Enable /Disable KPI",
                            marginBottom: "10px",
                          },
                        },
                        {
                          type: "text",
                          staticProps: {
                            content: "To make KPI enable or diable",
                            variant: "paragraph",
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "switch",
                    id: "kpi-enable-disable",
                    staticProps: {
                      className: "switch-alignment",
                      updatedDataName: "is_deleted",
                      customStyle: {
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "20%",
                      },
                    },
                    dataProps: {
                      data: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "is_deleted",
                      },
                      kpiDetails: {
                        type: "derived",
                        dataKey: "kpiDetails",
                      },
                    },
                  },
                ],
              },
            },

            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                  marginBottom: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h3",
                      content: "Kpi details",
                      marginBottom: "10px",
                    },
                  },
                ],
              },
            },

            {
              type: "div",
              id: "select-basic-details-wrapper",
              staticProps: {
                customStyle: {
                  display: "flex",
                  alignItems: "center",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "kpi_details",
                    core_id: "kpiDetails",
                    type: "form",
                    staticProps: {
                      customStyle: {
                        marginLeft: "20px",
                      },
                      fields: [
                        {
                          type: "select",
                          id: "category",
                          placeholder: "select",
                          variant: "outlined",
                          label: "Select KPI Category",
                          isMandatory: true,
                          showLabel: true,
                          updateSelectedOnEachSelection: true,
                        },
                        {
                          type: "select",
                          id: "version",
                          placeholder: "select",
                          variant: "outlined",
                          label: "Select KPI Version",
                          isMandatory: true,
                          showLabel: true,
                          updateSelectedOnEachSelection: true,
                        },
                        {
                          type: "plainText",
                          id: "kpiKey",
                          placeholder: "kPI key",
                          customStyle: {
                            width: "250px",
                            padding: "8px",
                            marginTop: "22px",
                            fontSize: "15px",
                            marginRight: "700px",
                          },
                        },
                        {
                          type: "select",
                          id: "name",
                          placeholder: "select",
                          variant: "outlined",
                          label: "KPI Name",
                          isMandatory: true,
                          showLabel: true,
                          updateSelectedOnEachSelection: true,
                          isDisabled: true,
                        },

                        {
                          type: "text",
                          id: "label",
                          variant: "outlined",
                          placeholder: "Enter here",
                          isMandatory: true,
                          label: "KPI Label",
                          showLabel: true,
                        },

                        {
                          type: "text",
                          id: "description",
                          placeholder: "Enter here...",
                          variant: "outlined",
                          label: "Kpi description(optional)",
                          isMandatory: false,
                          showLabel: true,
                        },
                        // {
                        //   type: "select",
                        //   id: "attribute",
                        //   placeholder: "select",
                        //   variant: "outlined",
                        //   label: "Select Version",
                        //   isMandatory: false,
                        //   showLabel: true,
                        //   updateSelectedOnEachSelection: true,
                        // },
                      ],
                    },
                    dataProps: {
                      kpiDetails: {
                        type: "derived",
                        dataKey: "kpiDetails",
                      },
                      kpiKey: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "kpi",
                      },
                      label: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "label",
                      },
                      description: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "description",
                      },
                      version: {
                        type: "derived",
                        dataKey: "requiredListKpiDetails",
                        subjectId: "versions",
                        dataType: "array",
                      },
                      core_details: {
                        type: "derived",
                        dataKey: "kpiDetails",
                      },
                      core_details1: {
                        type: "derived",
                        dataKey: "requiredListKpiDetails",
                      },
                      category: {
                        type: "derived",
                        dataKey: "requiredListKpiDetails",
                        subjectId: "category",
                        dataType: "array",
                      },
                      category_selected_option: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "category",
                        dataType: "array",
                      },
                      name: {
                        type: "derived",
                        dataKey: "requiredListKpiDetails",
                        subjectId: "kpis",
                        dataType: "array",
                      },
                      kpi_id: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "kpi",
                      },

                      name_selected_option: {
                        type: "derived",
                        dataKey: "kpiDetails",
                        subjectId: "name",
                      },
                      attribute: {
                        type: "derived",
                        dataKey: "filterData",
                        subjectId: "1",
                        subjectidNested: "array_agg",
                        dataType: "array",
                      },
                    },
                  },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "kpi-value-container",
              staticProps: {},
              componentProps: {
                content: [
                  {
                    type: "text",
                    id: "kpi-value",
                    staticProps: {
                      variant: "h3",
                      content: "Kpi Value",
                    },
                  },
                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "KPI Editabel",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                type: "switch",
                                id: "kpi-editable",

                                staticProps: {
                                  className: "switch-alignment",
                                  updatedDataName: "editable",
                                  customStyle: {
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: "20%",
                                  },
                                },
                                dataProps: {
                                  data: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "editable",
                                  },
                                  kpiDetails: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },
                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "KPI Formatting Value Type",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                type: "form",
                                id: "kpi_details",
                                core_id: "kpiDetails",
                                staticProps: {
                                  customStyle: {
                                    display: "flex",
                                    alignItems: "flex-start",
                                  },
                                  fields: [
                                    {
                                      type: "select",
                                      id: "formatter",
                                      variant: "outlined",
                                      isMandatory: true,
                                      showLabel: true,
                                      label: "Select KPI Value",
                                      updateSelectedOnEachSelection: true,
                                    },
                                  ],
                                },

                                dataProps: {
                                  formatter: {
                                    type: "derived",
                                    dataKey: "requiredListKpiDetails",
                                    subjectId: "value_types",
                                    dataType: "array",
                                  },
                                  core_details: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                  core_details1: {
                                    type: "derived",
                                    dataKey: "requiredListKpiDetails",
                                  },
                                  formatter_selected_option: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "formatter",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },
                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "KPI Decimals",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                id: "kpi_details",
                                core_id: "kpiDetails",
                                type: "form",
                                staticProps: {
                                  customStyle: {
                                    marginLeft: "20px",
                                  },
                                  fields: [
                                    {
                                      type: "text",
                                      id: "decimals",
                                      placeholder: "Enter here...",
                                      variant: "outlined",
                                      isMandatory: false,
                                      showLabel: true,
                                    },
                                    // {
                                    //   type: "select",
                                    //   id: "attribute",
                                    //   placeholder: "select",
                                    //   variant: "outlined",
                                    //   label: "Select Version",
                                    //   isMandatory: false,
                                    //   showLabel: true,
                                    //   updateSelectedOnEachSelection: true,
                                    // },
                                  ],
                                },
                                dataProps: {
                                  kpiDetails: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                  decimals: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "decimals",
                                  },

                                  core_details: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                  core_details1: {
                                    type: "derived",
                                    dataKey: "requiredListKpiDetails",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },

                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },

                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Advance Lock",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                id: "advance-lock",
                                type: "switch",
                                staticProps: {
                                  updatedDataName: "is_advance_lock",
                                  customStyle: {
                                    width: "auto",
                                  },
                                },
                                dataProps: {
                                  data: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "is_advance_lock",
                                  },
                                  kpiDetails: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },

                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "default_value",
                                staticProps: {
                                  content: "KPI Default Value",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "default_value",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                                dataProps: {
                                  default_value: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "default_value",
                                    dataType: "basic",
                                  },
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },

                          componentProps: {
                            content: [
                              {
                                id: "kpi_details",
                                core_id: "kpiDetails",
                                type: "form",
                                staticProps: {
                                  customStyle: {
                                    marginLeft: "20px",
                                  },
                                  fields: [
                                    {
                                      type: "text",
                                      id: "defaultValue",
                                      variant: "outlined",
                                      placeholder: "Enter here",
                                      showLabel: true,
                                    },
                                  ],
                                },
                                dataProps: {
                                  kpiDetails: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                  defaultValue: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "defaultValue",
                                  },

                                  core_details: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },

                                  core_details1: {
                                    type: "derived",
                                    dataKey: "requiredListKpiDetails",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },

                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },

                  {
                    type: "div",
                    id: "kpi-value-sub-container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "15px",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "KPI Options",
                                  variant: "h4",
                                },
                              },
                              {
                                type: "text",
                                id: "kpi-type",
                                staticProps: {
                                  content: "Some additional dummy text",
                                  variant: "paragraph",
                                },
                              },
                            ],
                          },
                        },

                        {
                          type: "div",
                          id: "select_container",
                          staticProps: {
                            customStyle: {
                              marginLeft: "400px",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                type: "checkbox",
                                id: "kpiDetails",
                                staticProps: {
                                  value: "Editable",
                                  checked: "true",
                                },
                                dataProps: {
                                  optionsValue: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                    subjectId: "options",
                                    dataType: "object",
                                  },
                                  kpiDetails: {
                                    type: "derived",
                                    dataKey: "kpiDetails",
                                  },
                                },
                              },
                            ],
                          },
                        },
                        {
                          id: "create-product-group-divider",
                          type: "divider",
                          staticProps: {
                            margin: "20px 0px",
                          },
                        },
                      ],
                    },
                  },

                  {
                    id: "create-product-group-divider",
                    type: "divider",
                    staticProps: {
                      margin: "20px 0px",
                    },
                  },
                  {
                    type: "div",
                    id: "editable_flows_wrapper",
                    staticProps: {
                      customStyle: {
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          staticProps: {
                            variant: "h4",
                            content: "Editable Flows",
                          },
                        },
                        {
                          type: "div",
                          id: "add_flow_button_container",
                          staticProps: {
                            customStyle: {
                              display: "flex",
                            },
                          },
                          componentProps: {
                            content: [
                              {
                                alignment: "right",
                                id: "strategy-workbench-create-button",
                                type: "button",
                                staticProps: {
                                  variant: "primary",
                                  label: "Add Flow",
                                  customStyle: {
                                    marginRight: "5px",
                                  },
                                  display: {
                                    type: "controlled",
                                    default: {
                                      show: true,
                                      enabled: true,
                                    },
                                  },
                                },
                                functionProps: [
                                  {
                                    functionName: "onClick",
                                    actions: [
                                      {
                                        type: "custom",
                                        function: () => {
                                          const data = getDataFromStore(
                                            "reducer",
                                            "uploadFlowFlag"
                                          );
                                          let flag = data ? data : false;
                                          loadResponseIntoStore(
                                            [
                                              {
                                                destination: "reducer",
                                                dataKey: "uploadFlowFlag",
                                                //updateExisitngValue: true,
                                              },
                                            ],
                                            {
                                              flag: !flag,
                                              clicked: true,
                                              flow: "ADD",
                                            }
                                          );
                                        },
                                      },
                                      // {
                                      //   type: "reducer_function",
                                      //   responseFormatter: [
                                      //     {
                                      //       destination: "reducer",
                                      //       dataKey: "kpiDetails",
                                      //       subjectId: "editable_flow",
                                      //       updateExistingNestedArray: true,
                                      //       dataType: "array",
                                      //       value: {
                                      //         name: "",
                                      //         value: [
                                      //           {
                                      //             formula: "",
                                      //             calculate_order: 1,
                                      //             kpi_calculate_id: null,
                                      //             kpi_calculate_name: "",
                                      //           },
                                      //         ],
                                      //         lock_and_hold: null,
                                      //       },
                                      //     },
                                      //   ],
                                      // },
                                    ],
                                  },
                                ],
                              },
                              {
                                alignment: "right",
                                id: "strategy-workbench-create-button",
                                type: "iconButton",
                                staticProps: {
                                  icon: 'file_upload',
                                  variant: "outlined",
                                  //label: "Upload",
                                  customStyle: {
                                    marginRight: "10px",
                                  },
                                  display: {
                                    type: "controlled",
                                    default: {
                                      show: true,
                                      enabled: true,
                                    },
                                  },
                                },
                                functionProps: [
                                  {
                                    functionName: "onClick",
                                    actions: [
                                      {
                                        type: "custom",
                                        function: () => {
                                          const data = getDataFromStore(
                                            "reducer",
                                            "uploadFlowFlag"
                                          );
                                          let flag = data ? data : false;
                                          loadResponseIntoStore(
                                            [
                                              {
                                                destination: "reducer",
                                                dataKey: "uploadFlowFlag",
                                                //updateExisitngValue: true,
                                              },
                                            ],
                                            {
                                              flag: !flag,
                                              clicked: true,
                                              flow: "UPLOAD",
                                            }
                                          );
                                        },
                                      },
                                      // {
                                      // 	type: "reducer_function",
                                      // 	responseFormatter: [
                                      // 		{
                                      // 			destination: "reducer",
                                      // 			dataKey: "kpiDetails",
                                      // 			subjectId: "editable_flow",
                                      // 			updateExistingNestedArray: true,
                                      // 			dataType: "array",
                                      // 			value: {
                                      // 				name: "",
                                      // 				kpi: null,
                                      // 				value: [
                                      // 					{
                                      // 						formula: "",
                                      // 						calculate_order: 1,
                                      // 						kpi_calculate_id: null,
                                      // 						kpi_calculate_name: "",
                                      // 					},
                                      // 				],
                                      // 				lock_and_hold: null,
                                      // 			},
                                      // 		},
                                      // 		{
                                      // 			destination: "reducer",
                                      // 			dataKey: "uploadFlowFlag",
                                      // 			//subjectId: "editable_flow",
                                      // 			//updateExistingNestedArray: true,
                                      // 			dataType: "boolean",
                                      // 			value: true
                                      // 		},
                                      // 	],
                                      // },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "editable_flows_sub_container",
                    staticProps: {
                      customStyle: {
                        padding: "10px",
                        marginTop: "5px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          id: "editable_flows_wrapper",
                          componentProps: {
                            content: [
                              {
                                type: "editableFlowsUploadTable",
                                dataProps: {
                                  kpiList: {
                                    type: "derived",
                                    dataKey: "kpiConfigurationList",
                                    subjectId: "0",
                                    subjectidNested: "data",
                                    dataType: "array",
                                  },
                                  kpiNames: {
                                    type: "derived",
                                    dataKey: "requiredListKpiDetails",
                                    subjectId: "kpis",
                                    dataType: "array",
                                  },
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "kpi-value-sub-container",
              staticProps: {
                customStyle: {
                  marginLeft: "15px",
                  display: "flex",
                  alignItems: "center",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    componentProps: {
                      content: [
                        {
                          type: "text",

                          staticProps: {
                            content: "Lock/Hold",
                            variant: "h4",
                          },
                        },
                        {
                          type: "text",
                          staticProps: {
                            content: "Some additional dummy text",
                            variant: "paragraph",
                          },
                        },
                      ],
                    },
                  },

                  {
                    type: "div",
                    id: "select_container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "400px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "form",
                          id: "kpi_details",
                          core_id: "kpiDetails",
                          staticProps: {
                            customStyle: {
                              display: "flex",
                              alignItems: "flex-start",
                            },
                            fields: [
                              {
                                type: "select",
                                id: "lock_and_hold",
                                variant: "outlined",
                                isMandatory: true,
                                showLabel: true,
                                label: "Select KPI Value",
                                updateSelectedOnEachSelection: true,
                              },
                            ],
                          },

                          dataProps: {
                            lock_and_hold: {
                              type: "derived",
                              dataKey: "requiredListKpiDetails",
                              subjectId: "lock_and_hold",
                              dataType: "array",
                            },
                            core_details: {
                              type: "derived",
                              dataKey: "kpiDetails",
                            },
                            core_details1: {
                              type: "derived",
                              dataKey: "requiredListKpiDetails",
                            },
                            lock_and_hold_selected_option: {
                              type: "derived",
                              dataKey: "kpiDetails",
                              subjectId: "lock_and_hold",
                            },
                          },
                        },
                        // {
                        //   type: "select",
                        //   id: "kpiDetails",
                        //   staticProps: {
                        //     isMultipleSelection: false,
                        //     hideClearSelection: true,
                        //     hideSearch: true,
                        //     updateSelectedOnEachSelection: true,
                        //   },
                        //   dataProps: {
                        //     options: {
                        //       type: "derived",
                        //       dataKey: "requiredListKpiDetails",
                        //       subjectId: "lock_and_hold",
                        //       dataType: "array",
                        //     },
                        //     kpiDetails: {
                        //       type: "derived",
                        //       dataKey: "kpiDetails",
                        //       dataType: "object",
                        //     },
                        //     selectedItem: {
                        //       type: "derived",
                        //       dataKey: "kpiDetails",
                        //       subjectId: "lock_and_hold",
                        //       subjectidNested: "label",
                        //       dataType: "string",
                        //     },
                        //   },
                        // },
                      ],
                    },
                  },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 49,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "reducer",
                    dataType: "basic",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    sourceId: "newConfig",
                    subjectId: "configDetails",
                    subjectidNested: "attributeId",
                  },
                  {
                    source: "reducer",
                    dataType: "basic",
                    sourceId: "selectedKpi",
                    subjectId: "kpi",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "kpi",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "kpiDetails",
                    dataType: "object",
                    overwrite: true,
                    multiLevelResponse: true,
                    level: 0,
                    level1: "data",
                    level2: 0,
                  },
                ],
                onComplete: {
                  actions: [
                    {
                      type: "api_function",
                      runOnLoad: true,
                      apiEndPoint: "model-data",
                      apiMethod: "post",
                      params: [
                        {
                          source: "fixed",
                          dataType: "object",
                          apiRequestKey: "id",
                          value: 52,
                        },
                        {
                          source: "reducer",
                          dataType: "basic",
                          sourceId: "selectedKpi",
                          subjectId: "kpi",
                          apiRequestKey: "parameters",
                          apiRequestKeyNested: "kpi",
                        },
                      ],
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey: "requiredListKpiDetails",
                          dataType: "array",
                          multiLevelResponse: true,
                          level: 0,
                          overwrite: true,
                        },
                      ],
                    },
                  ],
                },
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "screenName",
                    value: "KPI_DETAILS",
                  },
                ],
              },
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 20,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "versions",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
