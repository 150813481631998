import { margin } from "@mui/system";
import { DEFAULT_BASIC_DETAILS_FORM_DATA } from "../Constants";

export const NEW_CONFIG_PAGE = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",
        staticProps: {
          customStyle: {
            padding:'10px'
          }
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    type: "object",

                    value: {
                      screenName: "BASIC_DETAILS",
                      flow: "add",
                      formData: DEFAULT_BASIC_DETAILS_FORM_DATA,
                      updateExisting: true,
                    },
                  },
                ],
              },
              {
                type: "reducer_function",
                params: [
                  {
                    source: "reducer",
                    sourceId: "newConfig",
                    subjectId: "configDetails",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "formData",
                  },
                ],
              },
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 70,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "filterData",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
        componentProps: {
          content: [
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      content: "Config details",
                      customStyle: {
                        fontWeight: "600",
                        fontSize: "14px",
                        display: "flex",
                      },
                    },
                  },
                ],
              },
            },
            {
              id: "newConfig",
              type: "form",
              staticProps: {
                customStyle: {
                  width: "auto",
                },
                subjectId: "formData",
                fields: [
                  {
                    type: "text",
                    id: "config_name",
                    placeholder: "Enter plan name here",
                    variant: "outlined",
                    isMandatory: true,
                    label: "Config Name",
                    showLabel: true,
                    staticProps: {
                      variant: "h1",
                      customStyle: {
                        fontSize: "20px",
                      },
                    },
                  },
                  {
                    type: "text",
                    id: "config_description",
                    placeholder: "Enter here...",
                    variant: "outlined",
                    label: "Config description(optional)",
                    showLabel: true,
                  },
                ],
              },
              dataProps: {
                config_name: {
                  type: "derived",
                  dataKey: "newConfig",
                  subjectId: "formData",
                  subjectidNested: "config_name",
                },
                config_description: {
                  type: "derived",
                  dataKey: "newConfig",
                  subjectId: "formData",
                  subjectidNested: "config_description",
                },
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "40px 0px",
                color: "black",
              },
            },
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                className: "common-content-container ",
                customStyle: {
                  display: "flex",
                  padding: "0px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      content: "Configuration for hierarchy",
                      customStyle: {
                        fontWeight: "600",
                        fontSize: "14px",
                        display: "flex",
                      },
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "select-basic-details-wrapper",
              staticProps: {
                customStyle: {
                  display: "flex",
                  marginBottom: "10px",
                  height: "350px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "slect-basic-details",
                    componentProps: {
                      content: [
                        {
                          isDefaultExport: false,
                          type: "text",
                          staticProps: {
                            className: "common-page-title",
                            variant: "h4",
                            content: "Select Module",
                            customStyle: {
                              color: "#758490",
                              fontSize: "12px",
                              fontWeight: "400",
                              padding: "0 0px",
                            },
                          },
                        },
                        {
                          type: "basicDetailModuleSelection",
                          id: "select-module",
                          staticProps: {
                            dataKeyValue: "newConfig",
                            subjectIdValue: "formData",
                            subjectidNestedValue: "attributeId",
                            loadFunctionName: "loadEnableOptions",
                            fromPage: "newConfig",
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "filterData",
                              subjectId: "1",
                              subjectidNested: "array_agg",
                              dataType: "array",
                            },
                            data: {
                              type: "derived",
                              dataKey: "newConfig",
                              subjectId: "formData",
                              dataType: "object",
                            },
                            selectedConfig: {
                              type: "derived",
                              dataKey: "newConfig",
                              subjectId: "configDetails",
                            },
                            selectedModule: {
                              type: "derived",
                              dataKey: "newConfig",
                              subjectId: "select-module",
                              dataType: "array",
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "slect-channel-dropdown",
                    staticProps: {
                      customStyle: {
                        marginLeft: "10px",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: false,
                        },
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          isDefaultExport: false,
                          type: "text",
                          staticProps: {
                            className: "common-page-title",
                            variant: "h4",
                            content: "Select Channel",
                            customStyle: {
                              color: "#758490",
                              fontSize: "12px",
                              fontWeight: "100",
                              padding: "0 10px",
                            },
                          },
                        },
                        {
                          type: "basicDetailModuleSelection",
                          id: "select-channel",
                          staticProps: {
                            dataKeyValue: "newConfig",
                            subjectIdValue: "formData",
                            subjectidNestedValue: "attributeId",
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "filterData",
                              subjectId: "0",
                              subjectidNested: "array_agg",
                              dataType: "array",
                            },
                            selectedItem: {
                              type: "derived",
                              dataKey: "newConfig",
                              subjectId: "select-channel",
                              dataType: "array",
                            },
                            selectedConfig: {
                              type: "derived",
                              dataKey: "newConfig",
                              subjectId: "select-module",
                            },
                            selectedModule: {
                              type: "derived",
                              dataKey: "newConfig",
                              subjectId: "select-module",
                              dataType: "array",
                            },
                            selectedChannel: {
                              type: "derived",
                              dataKey: "newConfig",
                              subjectId: "select-channel",
                              dataType: "array",
                            },
                          },
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "slect-sub-channel-dropdown",
                    staticProps: {
                      customStyle: {
                        marginLeft: "10px",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: false,
                        },
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          isDefaultExport: false,
                          type: "text",
                          staticProps: {
                            className: "common-page-title",
                            variant: "h4",
                            content: "Select Sub Channel",
                            customStyle: {
                              color: "#758490",
                              fontSize: "12px",
                              fontWeight: "100",
                              padding: "0 10px",
                            },
                          },
                        },
                        {
                          type: "select",
                          id: "select-sub-channel",
                          staticProps: {
                            isMultipleSelection: false,
                            hideClearSelection: true,
                            hideSearch: true,
                            updateSelectedOnEachSelection: true,
                            // options: [
                            //   {
                            //     value: "s1",
                            //     label: "Wholesale",
                            //   },
                            // ],
                            // selectedOptions: [
                            //   {
                            //     value: "s1",
                            //     label: "Wholesale",
                            //   },
                            // ],
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "filterData",
                              forSelect: "sub-channel",
                              subjectId: "2",
                              subjectidNested: "array_agg",
                              dataType: "array",
                            },
                          },
                          // functionProps: [
                          //   {
                          //     functionName: "onSelect",
                          //     actions: [
                          //       {
                          //         type: "reducer_function",
                          //         params: [
                          //           {
                          //             source: "self",
                          //             apiRequestKey: "selectedData",
                          //             dataType: "array",
                          //           },
                          //         ],
                          //         responseFormatter: [
                          //           {
                          //             destination: "reducer",
                          //             dataKey: "sub-channel-select",
                          //             apiResponseKey: "selectedData",
                          //             dataType: "array",
                          //             overwrite: true,
                          //           },
                          //         ],
                          //       },
                          //     ],
                          //   },
                          // ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-remove-channel-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "28px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "channel-remove-button",
                          type: "iconButton",
                          staticProps: {
                            icon: "delete",
                            //variant: "primary",
                            iconClass: "colorLightSlate",
                            //className: "icon-button",
                            customStyle: {
                              margin: '2px',
                              height: '34px',
                              width: '34px',
                              background: "none",
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: false,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "slect-channel-dropdown",
                                      // subjectId: "decision-dashboard-edit-button",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "select-channel",
                                      apiResponseKey: "selectedData",
                                      dataType: "array",
                                      overwrite: true,
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button-channel",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                  ],
                                },

                                // changes accroding to the data received in api
                                {
                                  type: "reducer_function",
                                  params: [
                                    {
                                      source: "reducer",
                                      sourceId: "newConfig",
                                      subjectId: "select-module",
                                      subjectidNested: "id",
                                      dataType: "array",
                                    },
                                  ],
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "newConfig",
                                      subjectId: "formData",
                                      subjectidNested: "attributeId",
                                      updateNestedObject: true,
                                      dataType: "array",
                                    },
                                  ],
                                },
                                //clearing the selected channel
                                {
                                  type: "reducer_function",

                                  responseFormatter: [
                                    {
                                      destination: "fixed",
                                      dataKey: "newConfig",
                                      subjectId: "select-channel",
                                      value: {},
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-remove-sub-channel-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "28px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "sub-channel-remove-button",
                          type: "iconButton",
                          staticProps: {
                            // variant: "primary",
                            icon: "delete",
                            iconClass: "colorLightSlate",
                            // className: "icon-button",
                            customStyle: {
                              background: "none",
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: false,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "sub-channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "select-sub-channel",
                                      apiResponseKey: "selectedData",
                                      dataType: "array",
                                      overwrite: true,
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "channel-remove-button",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button-channel",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-add-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "28px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "decision-dashboard-edit-button",
                          type: "iconButton",
                          staticProps: {
                            variant: "text",
                            icon: "add",
                            iconClass: "colorWhite",
                            className: "icon-button",
                            size: 'small',
                            customStyle: {
                              margin: '2px 5px',
                              height: '34px',
                              width: '34px',
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "slect-channel-dropdown",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button-channel",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-add-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "28px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "decision-dashboard-edit-button-channel",
                          type: "iconButton",
                          staticProps: {
                            variant: "primary",
                            icon: "add",
                            iconClass: "colorWhite",
                            className: "icon-button",
                            customStyle: {
                              margin: '2px 5px',
                              height: '34px',
                              width: '34px',
                            },
                            display: {
                              type: "controlled",
                              default: {
                                show: false,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "sub-channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: true,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      subjectId: "channel-remove-button",
                                      // subjectId: "slect-sub-channel-dropdown",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                    {
                                      destination: "reducer",
                                      dataKey: "componentDisplayControls",
                                      // subjectId: "slect-channel-dropdown",
                                      subjectId:
                                        "decision-dashboard-edit-button-channel",
                                      dataType: "object",
                                      value: {
                                        show: false,
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    id: "basic-details-close-button",
                    staticProps: {
                      className: "common-content-container ",
                      customStyle: {
                        marginTop: "18px",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: false,
                        },
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          id: "decision-dashboard-close-button",
                          type: "iconButton",
                          staticProps: {
                            variant: "primary",
                            icon: "close",
                            iconClass: "colorWhite",
                            className: " icon-button",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
