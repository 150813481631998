import Button from "@mui/material/Button";
import _ from "lodash";
import { useNavigate } from "react-router-dom-v5-compat";
import "./Button.scss";
import { useEffect, useState } from "react";

const variant = {
  primary: "contained",
  secondary: "outlined",
  url: "text",
};

const ButtonComponent = (props) => {
  const navigate = useNavigate();
  const [svg, setSvg] = useState();
  const handleClick = (e) => {
    if (Array.isArray(props.onClick)) {
      props.onClick?.map(async (eachAction) => {
        if (typeof eachAction === "function") {
          const onCompleteActions = await eachAction(e);
          if (!_.isEmpty(onCompleteActions)) {
            // Execute the onComplete actions
            onCompleteActions?.map((action) => {
              if (action.type === "redirect") {
                navigate(`/${action.link}`);
              }
            });
          }
        } else if (typeof eachAction === "object") {
          navigate(`/${eachAction?.link}`);
        }
      });
    } else if (typeof props.onClick === "function") {
      props.onClick(e);
    }
  };
  useEffect(() => {
    if (props.iconType) {
      getSvg(props.icon);
    }
  }, []);

  const getSvg = (icon) => {
    import(`../../../assets/images/${icon}.svg`).then((module) =>
      setSvg(module.default)
    );
  };

  const getIcon = (icon) => {
    if (typeof icon === "string") {
      return <span class={`material-icons ${props.iconClass}`}>{icon}</span>;
    }
    return icon;
  };
  return (
    <div
      className={`ia-button ${
        props.componentDisplayControls?.[props.id]?.show === false
          ? "hide-component"
          : ""
      }`}
      style={{
        display:
          props.hasOwnProperty("is_visible") && props?.is_visible === false
            ? "none"
            : "",
        ...props.customWrapperStyle,
      }}
    >
      {props.label && (
        <Button
          onClick={handleClick}
          id={props.id}
          variant={variant[props.variant]}
          size={props.size}
          disabled={
            props.componentDisplayControls?.[props.id]?.enabled === false ||
            props?.isDisabled ||
            false
          }
          className={`${props.className ? props.className : ""}`}
          style={{
            margin: 0,
            cursor: "pointer",
            height: "35px",
            boxShadow: "none",
            ...props.customStyle,
          }}
        >
          {props.icon && !props.iconType && (
            <span className="icon">{props.icon && getIcon(props.icon)}</span>
          )}
          {props.icon && props.iconType && (
            <span className="icon non-material">
              <img src={svg} alt="" />
            </span>
          )}
          {props.label}
        </Button>
      )}
    </div>
  );
};

export default ButtonComponent;
