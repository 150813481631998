export const EDIT_DISAGGREGATION_STRATEGY = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            margin: "2px 10px",
            // border: "1px solid",
            // borderRadius: "10px",
            // borderColor: "#808080",
            height: "600px",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "basic-details-sub-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h1",
                      content: "Edit Aggregation Strategy",
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "kpi-value-sub-container",
              staticProps: {
                customStyle: {
                  marginLeft: "15px",
                  display: "flex",
                  alignItems: "center",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          id: "kpi-type",
                          staticProps: {
                            content: "Select KPI",
                            variant: "h4",
                          },
                        },
                        {
                          type: "text",
                          id: "kpi-type",
                          staticProps: {
                            content: "Some additional dummy text",
                            variant: "paragraph",
                          },
                        },
                      ],
                    },
                  },

                  {
                    type: "div",
                    id: "select_container",
                    staticProps: {
                      customStyle: {
                        marginLeft: "400px",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "select",
                          id: "editAggregationKpi",
                          staticProps: {
                            isMultipleSelection: false,
                            hideClearSelection: true,
                            hideSearch: true,
                            updateSelectedOnEachSelection: true,
                            selectedOptionFromApi: true,
                            apiDataKey: "selectedKpi",
                            subjectId: "selectedKpi",
                          },
                          dataProps: {
                            options: {
                              type: "derived",
                              dataKey: "requiredListKpiDetails",
                              subjectId: "kpis",
                              dataType: "array",
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },

            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
                color: "black",
              },
            },
            {
              id: "aggregationStrategyForm",
              type: "form",
              staticProps: {
                dataKey: "aggregationStrategyForm",
                customStyle: {
                  width: "auto",
                },
                fields: [
                  {
                    type: "text",
                    id: "product_roll_down",
                    variant: "outlined",
                    placeholder: "Enter here",
                    label: "Product Hierarchy",
                    showLabel: true,
                  },
                  {
                    type: "text",
                    id: "rollup_channel",
                    placeholder: "Enter here...",
                    variant: "outlined",
                    label: "With 0's in Values",
                    showLabel: true,
                  },
                  {
                    type: "text",
                    id: "roll_down_timeline",
                    placeholder: "Enter here...",
                    variant: "outlined",
                    label: "Roll Down Time",
                    showLabel: true,
                  },
                ],
              },
              dataProps: {
                product_roll_down: {
                  type: "derived",
                  dataKey: "selectedKpi",
                  subjectId: "product_roll_down",
                },
                rollup_channel: {
                  type: "derived",
                  dataKey: "selectedKpi",
                  subjectId: "update_spread_logic",
                },
                roll_down_timeline: {
                  type: "derived",
                  dataKey: "selectedKpi",
                  subjectId: "roll_down_timeline",
                },
              },
            },
            {
              type: "div",
              id: "basic-details-buttons",
              staticProps: {
                customStyle: {
                  paddingBottom: "10px",
                  width: "100%",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "bottom-divider",
                    componentProps: {
                      content: [
                        {
                          id: "create-product-group-divider",
                          type: "divider",
                          staticProps: {
                            color: "black",
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "screenName",
                    value: "EDIT_DISAGGREGATION_STRATEGY",
                  },
                ],
              },
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 52,
                  },
                  {
                    source: "reducer",
                    dataType: "basic",
                    sourceId: "selectedKpi",
                    subjectId: "kpi",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "kpi",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "requiredListKpiDetails",
                    dataType: "array",
                    multiLevelResponse: true,
                    level: 0,
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
