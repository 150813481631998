export const PLANING_SCREEN = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "planning-screen-content-emptystate",
        staticProps: {
          customStyle: {
            padding:'10px'
          }
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 50,
                  },
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "parameters",
                    value: {},
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "filterLevelValues",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "screenName",
                    value: "PLANNING_SCREEN",
                  },
                ],
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "formData",
                    value: {},
                  },
                ],
              },
              {
                type: "reducer_function",
                params: [
                  {
                    source: "reducer",
                    dataType: "object",
                    sourceId: "newConfig",
                    subjectId: "configDetails",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "configDetails",
                  },
                ],
              },
            ],
          },
        ],
        componentProps: {
          content: [
            {
              type: "text",
              staticProps: {
                variant: "h4",
                content: "Select Product Level*",
              },
            },
            {
              type: "div",
              id: "pentagon-wrapper",
              componentProps: {
                content: [
                  {
                    id: "SelectedProductLevel",
                    type: "pentagonButtons",
                    staticProps: {
                      variant: "outlined",
                      className: "",
                      isMultiSelect: true,
                      label: "planFliters",
                      maxFilterAllowed: 2,
                      customStyle: {
                        margin: 0,
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      },
                    },
                    dataProps: {
                      buttons: {
                        type: "derived",
                        dataKey: "filterLevelValues",
                        subjectId: "1",
                        subjectidNested: "value",
                        subjectidNested1: "product_level",
                        saveWithLabel: "pcd_ids",
                        dataType: "array",
                      },
                    },

                    // staticProps: {

                    //   parentId: "temp_selected_filters_for_step4",
                    //   filtersForPayload: "tempFiltersForPayloadStep4",
                    //   changeFlagStatus: "trackSettingsChange",
                    // },
                    // dataProps: {
                    //   buttons: {
                    //     type: "derived",
                    //     dataKey: "filters_for_step_4",
                    //     subjectId: "pcd_metrics",
                    //     saveWithLabel: "pcd_ids",
                    //     dataType: "array",
                    //   },
                    //   defaultActiveButtonIds: {
                    //     type: "derived",
                    //     dataKey: "filtersForPayloadStep4",
                    //     subjectId: "pcd_ids",
                    //   },
                    // },
                  },
                ],
              },
            },
            {
              type: "text",
              staticProps: {
                variant: "h4",
                content: "Select channel level*",
              },
            },
            {
              type: "div",
              id: "pentagon-wrapper",
              componentProps: {
                content: [
                  {
                    id: "SelectedChannelLevel",
                    type: "pentagonButtons",
                    staticProps: {
                      variant: "outlined",
                      className: "",
                      isMultiSelect: true,
                      label: "planFliters",
                      maxFilterAllowed: 1,
                      customStyle: {
                        margin: 0,
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      },
                      // buttons: [
                      //   {
                      //     id: "L0",
                      //     label: "Distribution",
                      //   },
                      //   {
                      //     id: "L1",
                      //     label: "Region",
                      //   },
                      // ],
                      // defaultActiveButtonId: 0,
                    },
                    dataProps: {
                      buttons: {
                        type: "derived",
                        dataKey: "filterLevelValues",
                        subjectId: "0",
                        subjectidNested: "value",
                        subjectidNested1: "channel_level",
                        saveWithLabel: "pcd_ids",
                        dataType: "array",
                      },
                      selectedConfig: {
                        type: "derived",
                        dataKey: "selectedConfig",
                        subjectId: "config",
                      },
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "bottom-buttons",
              staticProps: {
                customStyle: {
                  marginTop: "10px",
                  marginBottom: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "save-preview-button",
                    type: "button",
                    staticProps: {
                      variant: "secondary",
                      label: "Save & Preview",
                      customStyle: {
                        border: "1px solid",
                      },
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "planning-screen-level-table",
              staticProps: {
                customStyle: {
                  justifyContent: "center",
                  border: "1px solid",
                  borderRadius: "10px",
                  borderColor: "#808080",
                  padding: "10px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h4",
                      content: "Preview Table",
                    },
                  },
                  {
                    type: "text",
                    staticProps: {
                      customStyle: {
                        display: "flex",
                        alignItems: "flex-start",
                      },
                      content:
                        "Note: Table name can be renames by double clicking on the names",
                    },
                  },

                  {
                    type: "aggrid",
                    staticProps: {
                      id: "planning-level-screen",
                      height: "400px",
                      rowSelection: "multiple",
                      uniqueKey: "unique_key",
                      isRowSelectable: (rowData) => {
                        return !rowData.data.is_sub_total;
                      },
                      getRowStyle: (params) => {
                        return params?.data?.is_sub_total
                          ? {
                              background: "#F7F7F7",
                              borderBottom: "2px solid #758490",
                            }
                          : {};
                      },
                      tableConfig: {
                        column_headers: [
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Department",
                            key: "department",
                            suppressToolPanel: true,
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                            cellStyle: {
                              background: "#f7f7f7",
                            },
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Division",
                            key: "division",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Class",
                            key: "class_name",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Sub Class",
                            key: "sub_class",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Category",
                            key: "kpi_category_name",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                            hideColumnOnClick: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Distribution",
                            key: "distribution",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Region",
                            key: "region",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: false,
                            filter: false,
                            pinned: true,
                            lockPinned: true,
                            row_group: false,
                            suppressMenu: true,
                          },

                          {
                            header: "Jan",
                            key: "pcd_metrics",
                            marryChildren: true,
                            columnGroupShow: "closed",
                            columns: [
                              {
                                header: "Jan Total",
                                key: "",
                                marryChildren: true,
                                columns: [
                                  {
                                    data_type: "number",
                                    is_edit: false,
                                    extra: {
                                      weekValue: 2904,
                                      metric: "price_point",
                                      disableLockAtTableLevel: false,
                                    },
                                    marryChildren: true,
                                    header: "Omni",
                                    minWidth: 220,
                                    key: "pcd_metrics_pcd_2904_clearance_ia_reco_discount_percent",
                                  },
                                ],
                              },
                              {
                                columnGroupShow: "open",
                                header: "Week1",
                                key: "pcd_2904",
                                marryChildren: true,
                                columns: [
                                  {
                                    data_type: "number",
                                    aggFunc: "sum",
                                    is_edit: false,
                                    extra: {
                                      weekValue: 2904,
                                      metric: "margin_dollar",
                                      disableLockAtTableLevel: false,
                                    },
                                    marryChildren: true,
                                    columnGroupShow: "closed",
                                    header: "Omni",
                                    key: "pcd_metrics_pcd_2904_margin_ia_reco",
                                  },
                                ],
                              },
                              {
                                columnGroupShow: "open",
                                header: "week2",
                                key: "pcd_2904",
                                marryChildren: true,
                                columns: [
                                  {
                                    data_type: "number",
                                    aggFunc: "sum",
                                    is_edit: false,
                                    marryChildren: true,
                                    columnGroupShow: "closed",
                                    header: "Omni",
                                    key: "pcd_metrics_pcd_2904_revenue_ia_reco",
                                  },
                                ],
                              },
                              {
                                columnGroupShow: "open",
                                header: "week3",
                                key: "pcd_2904",
                                marryChildren: true,
                                columns: [
                                  {
                                    data_type: "number",
                                    aggFunc: "sum",
                                    is_edit: false,
                                    extra: {
                                      weekValue: 2904,
                                      metric: "sales_units",
                                      disableLockAtTableLevel: false,
                                    },
                                    marryChildren: true,
                                    header: "Omni",
                                    key: "pcd_metrics_pcd_2904_unit_ia_reco",
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Feb",
                            columns: [
                              {
                                type: "static_column",
                                mapping: "data",
                                header: "Feb Total",
                                key: "",
                                suppressMenu: true,
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: true,
                                columns: [
                                  {
                                    type: "static_column",
                                    mapping: "data",
                                    header: "Omni",
                                    key: "",
                                    suppressMenu: true,
                                    action: null,
                                    hidden: false,
                                    aggregate: null,
                                    sorting: false,
                                    filter: false,
                                    pinned: false,
                                    row_group: false,
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "March",
                            columns: [
                              {
                                type: "static_column",
                                mapping: "data",
                                header: "March Total",
                                key: "",
                                suppressMenu: true,
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: false,
                                columns: [
                                  {
                                    type: "static_column",
                                    mapping: "data",
                                    header: "Omni",
                                    key: "",
                                    suppressMenu: true,
                                    action: null,
                                    hidden: false,
                                    aggregate: null,
                                    sorting: false,
                                    filter: false,
                                    pinned: false,
                                    row_group: false,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    },
                    dataProps: {
                      slectedProductFilters: {
                        type: "derived",
                        dataKey: "newConfig",
                        subjectId: "formData",
                        subjectidNested: "SelectedProductLevel",
                        dataType: "array",
                      },
                      slectedChannelFilters: {
                        type: "derived",
                        dataKey: "newConfig",
                        subjectId: "formData",
                        subjectidNested: "SelectedChannelLevel",
                        dataType: "array",
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
