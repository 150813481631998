export const KPI_MASTER_LIST = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "kpi-master-list",
        staticProps: {
          customStyle: {
            padding:'10px',
            display: "flex",
            justifyContent: "space-between",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "decision-dashboard-content",
              staticProps: {
                className: "common-content-container",
              },
              componentProps: {
                content: [
                  {
                    type: "SelectedHierarchy",
                  },
                  // {
                  //   type: "selected_chips",
                  //   pathSrc: "components",
                  //   componentPath: "ui/selectedChips/SelectedChips",
                  //   staticProps: {
                  //     title: "Selected Filters:",
                  //     customStyle: {
                  //       margin: 0,
                  //     },
                  //     shouldClear: false,
                  //     chipStyle: {
                  //       borderRadius: "3px",
                  //       maxWidth: "unset",
                  //       height: "20px",
                  //     },
                  //     show_chips: true,
                  //   },
                  //   dataProps: {
                  //     // show_chips: {
                  //     // 	type: "derived",
                  //     // 	dataKey:
                  //     // 		"show_chips_for_ia_filter",
                  //     // },
                  //     data: {
                  //       type: "derived",
                  //       dataKey: "selected_filters_for_step4",
                  //       dataType: "array",
                  //     },
                  //   },
                  // },
                ],
              },
            },
            {
              type: "div",
              id: "decision-dashboard-content",
              staticProps: {
                className: "common-content-container",
                customStyle: {
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "grid-wrapper-div",
                    type: "div",
                    staticProps: {
                      className: "common-content-container",
                      marginRight: "10px",
                    },
                    componentProps: {
                      content: [
                        {
                          id: "switch-comp-dashboard",
                          type: "switch",
                          staticProps: {
                            className: "switch-alignment",
                            isCustom: true,
                            customStyle: {
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            },
                            rightLabel: " Show Custom",
                          },
                          dataProps: {
                            data: {
                              type: "derived",
                              dataKey: "kpiConfigurationList",
                              dataType: "array",
                            },
                            actualData: {
                              type: "derived",
                              dataKey: "actualKpiConfigurationList",
                              dataType: "array",
                            },
                          },
                        },
                      ],
                    },
                  },
                  // {
                  //   id: "divider-main-dashboard",
                  //   type: "div",
                  //   staticProps: {
                  //     customStyle: {
                  //       height: "20px",
                  //       width: "1px",
                  //       backgroundColor: "rgba(0, 0, 0, 0.12)",
                  //     },
                  //   },
                  // },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 49,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "reducer",
                    dataType: "basic",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    sourceId: "newConfig",
                    subjectId: "configDetails",
                    subjectidNested: "attributeId",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "kpiConfigurationList",
                    dataType: "array",
                    overwrite: true,
                  },
                  {
                    destination: "reducer",
                    dataKey: "actualKpiConfigurationList",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "screenName",
                    value: "KPI_LIST",
                  },
                ],
              },
              {
                type: "reducer_function",
                params: [
                  {
                    source: "reducer",
                    dataType: "object",
                    sourceId: "newConfig",
                    subjectId: "configDetails",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "configDetails",
                  },
                ],
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    subjectId: "formData",
                    value: {},
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "div",
        id: "workbench-metrics-table",
        staticProps: {
          className: "common-content-container",
          customStyle: {
            marginTop: "20px",
            marginLeft: "10px",
            marginRight: "10px",
          },
        },
        componentProps: {
          content: [
            {
              type: "aggrid",
              staticProps: {
                height: "475px",
                tableId: "decision_dashboard_strategies_product_count",
                rowSelection: "multiple",
                onClickCell: "navigate",
                width: "100%",
                uniqueKey: "kpi",
                floatingFilter: true,
                tableConfig: {
                  column_headers: [
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "",
                      key: "",
                      pinned: true,
                      lockPinned: true,
                      checkbox_selection: true,
                      row_group: false,
                      suppressHeaderMenuButton: true,
                      maxWidth: 50,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Enable",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: true,
                      row_group: false,
                      cellRenderer: "SwitchCellrenderer",
                      cellRendererParams: {
                        field: "is_deleted",
                      },
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Details",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "KPI Name",
                          key: "name",
                          filter: true,
                          cellRenderer: "customIconClick",
                          cellRendererParams: {
                            actions: [
                              {
                                type: "reducer_function",
                                responseFormatter: [
                                  {
                                    destination: "reducer",
                                    dataKey: "loadEditKpidetails",
                                    value: true,
                                  },
                                ],
                              },
                              {
                                type: "reducer_function",
                                params: [
                                  {
                                    source: "self",
                                    apiRequestKey: "selectedKpi",
                                    dataType: "basic",
                                  },
                                ],
                                responseFormatter: [
                                  {
                                    destination: "reducer",
                                    dataKey: "selectedKpi",
                                    apiResponseKey: "selectedKpi",
                                    dataType: "basic",
                                  },
                                ],
                              },
                              // {
                              //   type: "reducer_function",
                              //   responseFormatter: [
                              //     {
                              //       destination: "reducer",
                              //       dataKey: "screenName",
                              //       value: "KPI_MASTER",
                              //       dataType: "basic",
                              //     },
                              //   ],
                              // },
                              {
                                type: "redirect",
                                link: "add-new-config",
                              },
                            ],
                          },
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "KPI Category",
                          key: "category.label",
                          filter: true,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Label",
                          key: "label",
                          filter: true,
                        },
                      ],
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Description",
                      key: "description",
                      filter: true,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Order",
                      key: "order",
                      filter: true,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Formatting",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Default Value",
                          key: "formatter.label",
                          action: null,
                          //filter: true,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Value Type",
                          key: "type",
                          //filter: true,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Decimal",
                          key: "decimals",
                          //filter: true,
                        },
                      ],
                    },
                  ],
                },
              },
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "kpiConfigurationList",
                  subjectId: "0",
                  subjectidNested: "data",
                  dataType: "array",
                },
              },
            },
          ],
        },
      },

      // {
      //   type: "div",
      //   id: "decision-dashboard-content",
      //   staticProps: {
      //     className: "common-content-container",
      //     customStyle: {
      //       display: "flex",
      //       justifyContent: "flex-end",
      //       alignItems: "center",
      //       margin: "10px",
      //     },
      //   },
      //   componentProps: {
      //     content: [
      //       {
      //         id: "cancel-button-dashboard",
      //         type: "div",
      //         staticProps: {
      //           className: "common-button-container",
      //         },
      //         componentProps: {
      //           content: [
      //             {
      //               alignment: "right",
      //               id: "strategy-workbench-create-button",
      //               type: "button",
      //               staticProps: {
      //                 variant: "text",
      //                 label: "Cancel",
      //               },
      //               functionProps: [
      //                 {
      //                   functionName: "onClick",
      //                   actions: [
      //                     {
      //                       type: "redirect",
      //                       link: "landing-screen",
      //                     },
      //                   ],
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       },
      //       {
      //         id: "save-button-dashboard",
      //         type: "div",
      //         staticProps: {
      //           className: "common-button-container",
      //         },
      //         componentProps: {
      //           content: [
      //             {
      //               alignment: "right",
      //               id: "strategy-workbench-create-button",
      //               type: "button",
      //               staticProps: {
      //                 variant: "outlined",
      //                 label: "Save as Draft",
      //               },
      //             },
      //           ],
      //         },
      //       },
      //       {
      //         id: "next-button-dashboard",
      //         type: "div",
      //         staticProps: {
      //           className: "common-button-container",
      //         },
      //         componentProps: {
      //           content: [
      //             {
      //               alignment: "right",
      //               id: "strategy-workbench-create-button",
      //               type: "button",
      //               staticProps: {
      //                 variant: "primary",
      //                 label: "Next",
      //               },
      //             },
      //           ],
      //         },
      //       },
      //     ],
      //   },
      // },
    ],
  },
};
