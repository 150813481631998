export const KPI_SELECTOR = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "kpi-master-list",
        staticProps: {
          customStyle: {
            display: "flex",
            justifyContent: "space-between",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "decision-dashboard-content",
              staticProps: {
                className: "common-content-container",
              },
              componentProps: {
                content: [
                  {
                    type: "selected_chips",
                    pathSrc: "components",
                    componentPath: "ui/selectedChips/SelectedChips",
                    staticProps: {
                      title: "Selected Filters:",
                      customStyle: {
                        margin: 0,
                      },
                      shouldClear: false,
                      chipStyle: {
                        borderRadius: "3px",
                        maxWidth: "unset",
                        height: "20px",
                      },
                      show_chips: true,
                    },
                    dataProps: {
                      // show_chips: {
                      // 	type: "derived",
                      // 	dataKey:
                      // 		"show_chips_for_ia_filter",
                      // },
                    },
                  },
                ],
              },
            },
            {
              type: "div",
              id: "decision-dashboard-content",
              staticProps: {
                className: "common-content-container",
                customStyle: {
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                },
              },
              componentProps: {
                content: [
                  {
                    id: "grid-wrapper-div",
                    type: "div",
                    staticProps: {
                      className: "common-content-container",
                      marginRight: "10px",
                    },
                    componentProps: {
                      content: [
                        {
                          id: "switch-comp-dashboard",
                          type: "switch",
                          staticProps: {
                            className: "switch-alignment",
                            customStyle: {
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            },
                            rightLabel: " Show Custom",
                          },
                        },
                      ],
                    },
                  },
                  {
                    id: "divider-main-dashboard",
                    type: "div",
                    staticProps: {
                      customStyle: {
                        height: "20px",
                        width: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.12)",
                      },
                    },
                  },
                  {
                    id: "divider-main-dashboard",
                    type: "div",
                    staticProps: {
                      className: "common-content-container",
                    },
                    componentProps: {
                      content: [
                        {
                          alignment: "right",
                          id: "strategy-workbench-create-button",
                          type: "button",
                          staticProps: {
                            variant: "primary",
                            label: "Add New KPI",
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 57,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "1",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "kpiConfigurationList",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: "div",
        id: "workbench-metrics-table",
        staticProps: {
          className: "common-content-container",
          customStyle: {
            marginTop: "20px",
          },
        },
        componentProps: {
          content: [
            {
              type: "aggrid",
              staticProps: {
                height: "400px",
                tableId: "decision_dashboard_strategies_product_count",
                rowSelection: "multiple",
                onClickCell: "navigate",
                uniqueKey: "kpi",
                tableConfig: {
                  column_headers: [
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "",
                      key: "",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: true,
                      lockPinned: true,
                      checkbox_selection: true,
                      row_group: false,
                      suppressMenu: true,
                      maxWidth: 50,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Enable/Disable",
                      key: "",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: false,
                      filter: false,
                      pinned: true,
                      row_group: false,
                      cellRenderer: "CustomIcon",
                    },
                    // {
                    //   type: "static_column",
                    //   mapping: "data",
                    //   header: "KPI Details",
                    //   columns: [
                    //     {
                    //       type: "static_column",
                    //       mapping: "data",
                    //       header: "KPI Name",
                    //       key: "name",
                    //       action: null,
                    //       hidden: false,
                    //       aggregate: null,
                    //       sorting: false,
                    //       filter: false,
                    //       pinned: true,
                    //       row_group: false,
                    //       cellRenderer: "customIconClick",
                    //     },
                    //     {
                    //       type: "static_column",
                    //       mapping: "data",
                    //       header: "KPI Category",
                    //       key: "category.label",
                    //       action: null,
                    //       hidden: false,
                    //       aggregate: null,
                    //       sorting: false,
                    //       filter: false,
                    //       pinned: true,
                    //       row_group: false,
                    //     },
                    //     {
                    //       type: "static_column",
                    //       mapping: "data",
                    //       header: "Label",
                    //       key: "label",
                    //       action: null,
                    //       hidden: false,
                    //       aggregate: null,
                    //       sorting: false,
                    //       filter: false,
                    //       pinned: true,
                    //       row_group: false,
                    //     },
                    //     {
                    //       type: "static_column",
                    //       mapping: "data",
                    //       header: "KPI Key",
                    //       key: "kpi_key",
                    //       action: null,
                    //       hidden: false,
                    //       aggregate: null,
                    //       sorting: false,
                    //       filter: false,
                    //       pinned: true,
                    //       row_group: false,
                    //     },
                    //   ],
                    // },
                    // {
                    //   type: "static_column",
                    //   mapping: "data",
                    //   header: "KPI Description",
                    //   key: "kpi_description",
                    //   action: "",
                    //   hidden: false,
                    //   aggregate: null,
                    //   sorting: false,
                    //   filter: false,
                    //   pinned: false,
                    //   checkbox_selection: false,
                    //   row_group: false,
                    // },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Value",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Type",
                          key: "type",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Static Value",
                          key: "static_value",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Default Value",
                          key: "default_value",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                        },
                      ],
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Formatting",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Value type",
                          key: "formatter.value.type",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Decimals",
                          key: "decimals",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                        },
                      ],
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "KPI Formula",
                      columns: [
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Calculation Formula",
                          key: "formatter.label",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Lock/Hold",
                          key: "lock_hold",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                        },
                        {
                          type: "static_column",
                          mapping: "data",
                          header: "Change",
                          key: "change",
                          action: null,
                          hidden: false,
                          aggregate: null,
                          sorting: false,
                          filter: false,
                          pinned: false,
                          row_group: false,
                        },
                      ],
                    },
                  ],
                },
              },
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "kpiConfigurationList",
                  subjectId: "0",
                  subjectidNested: "data",
                  dataType: "array",
                },
                icons: ["checkCircle"],
              },
            },
          ],
        },
      },

      {
        type: "div",
        id: "decision-dashboard-content",
        staticProps: {
          className: "common-content-container",
          customStyle: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          },
        },
        componentProps: {
          content: [
            {
              id: "cancel-button-dashboard",
              type: "div",
              staticProps: {
                className: "common-button-container",
              },
              componentProps: {
                content: [
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "text",
                      label: "Cancel",
                    },
                  },
                ],
              },
            },
            {
              id: "save-button-dashboard",
              type: "div",
              staticProps: {
                className: "common-button-container",
              },
              componentProps: {
                content: [
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "outlined",
                      label: "Save",
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                      },
                    ],
                  },
                ],
              },
            },
            {
              id: "next-button-dashboard",
              type: "div",
              staticProps: {
                className: "common-button-container",
              },
              componentProps: {
                content: [
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "primary",
                      label: "Next",
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
