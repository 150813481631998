import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { useDispatch, connect } from "react-redux";
//import { Navigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoginForm from "./components/LoginForm";
import { VALID_EMAIL } from "../../utils/regex";
import { signIn, samlLoginCallback } from "../../store/features/auth/auth";
import MarketingBanner from "./components/MarketingBanner";
import AnalyticsContext from "../../utils/postHog/CustomAnalyticsContext";
import logo from "./../../assets/images/color-logo.png";
import "./login.scss";
import LoadingOverlay from "../ui/loader/Loader";
import { useNavigate } from "react-router-dom-v5-compat";

const theme = createTheme();

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputError, setInputError] = useState({});

  const dispatch = useDispatch();
  const analytics = useContext(AnalyticsContext);
  const navigate = useNavigate();
  // If user is already logged in, redirect to decision dashboard
  useEffect(() => {
    let token = localStorage.getItem("token");
    // remove this line after successful login implementation
    if (token) {
      window.location.href = "/landing-screen";
    }
    if (!token) {
      console.error("Token is not generated");
    }
  }, [props.token]);

  const onChangeHandler = (e) => {
    let value = e.target.value;
    value = value && value.trim();
    if (e.target.name === "email") {
      setEmail(value);
    }
    if (e.target.name === "password") {
      setPassword(value);
    }
  };

  const verifyUserData = () => {
    let error = {};
    if (!email || !VALID_EMAIL.test(email)) {
      error.email = true; //"Please input valid email id";
    }
    if (!password) {
      error.password = true; //"Please enter password";
    }
    if (Object.keys(error).length) {
      setInputError(error);
      return false;
    } else {
      return true;
    }
  };

  const clearError = () => {
    if (Object.keys(inputError).length) {
      setInputError({});
    }
  };

  const handleSubmit = (e) => {
    if (e.clientId) {
      console.log(e);
    } else {
      e.preventDefault();
      if (verifyUserData()) {
        clearError();
        const userData = {
          email: email,
          password: password,
        };
        dispatch(signIn(userData));
      }
    }
  };

  if (props.token) {
    analytics.trackLogin(email);
    //return <Navigate to="/landing-screen" replace />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main">
        <Grid item xs={12}>
          <div className="signin-form__header">
            <img src={logo} alt="logo" className="header__logo" />
            <Typography component="h1" variant="h2" className="brand__title">
              Plan Smart
            </Typography>
          </div>
        </Grid>
        <Grid container item xs={6} sm={6} justifyContent="center">
          <div className="login-options">
            <div className="login__wrapper">
              <LoginForm
                email={email}
                password={password}
                onChangeHandler={onChangeHandler}
                handleSubmit={handleSubmit}
                inputError={inputError}
                samlLoginCallback={samlLoginCallback}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} justifyContent="center">
          <MarketingBanner />
        </Grid>
      </Grid>
      <LoadingOverlay showLoader={props.loading !== 0} />
    </ThemeProvider>
  );
}

const mapStateToProps = (store) => {
  return {
    token: store.auth.token,
    loading: store.global.loading,
  };
};

export default connect(mapStateToProps)(Login);
