import React, { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom-v5-compat";

const ProtectedRoutes = (props) => {
	const isAuthenticated = useMemo(() => localStorage.getItem("token"), [])
	let location = useLocation();
	if(!isAuthenticated) {
		return <Navigate to="/login" state={{from : location}} replace/>
	}
	return props.children;
}

export default ProtectedRoutes;
