import { createTheme } from "@mui/material/styles";
import colours from "./Styles/colours";
const theme = createTheme({
	customVariables: {
		closedNavWidth: "4.5rem",
		navWidth: "20.75rem",
		commentDrawerWidth: 50,
	},
	typography: {
		fontFamily: "Poppins",
		allVariants: {
			fontSize: "0.8rem",
		},
	},
	palette: {
			common: {
			  black: colours.black,
			  white: colours.white,
			},
			// primary: {
			//   lighter: colours.linkWater,
			//   light: colours.cerulean,
			//   lightest: colours.bubbles,
			//   main: colours.endavour,
			//   dark: colours.greenVogue,
			//   contrastText: colours.white,
			// },
			error: {
			  light: colours.wispPink,
			  main: colours.alizarinCrimson,
			  contrastText: colours.white,
			},
			warning: {
			  lighter: colours.creamCan,
			  lightest: colours.ginfizz,
			  light: colours.seashellPeach,
			  gold: colours.goldSand,
			  main: colours.crusta,
			  contrastText: colours.white,
			},
			success: {
			  light: colours.panache,
			  main: colours.eucalyptus,
			  info: colours.yellowGreen,
			  contrastText: colours.white,
			},
			tertiary: {
			  main: colours.silver,
			  light: colours.concrete,
			},
			text: {
			  primary: colours.codGray,
			  secondary: colours.fiord,
			  disabled: colours.silverChalice,
			  hint: colours.tiara,
			  icon: "rgba(255, 255, 255, 0.7)",
			  disabledText: colours.hoki,
			},
			background: {
			  paper: colours.white,
			  primary: colours.athensGray,
			  default: colours.white,
			  warningInfo: colours.princetonOrange,
			  infoBg: colours.lightGrey,
			  filterChips: colours.selago,
			  chipBackground: colours.athensGrayLight,
			  tagBackground: colours.linkWaterDark,
			},
			graphColours: [
			  colours.endavour,
			  colours.emerald,
			  colours.selectiveYellow,
			  colours.jaffa,
			  colours.froly,
			  colours.java,
			  colours.cerulean,
			  colours.pastelGreen,
			  colours.dandelion,
			  colours.atomicTangerine,
			  colours.sweetPink,
			  colours.spray,
			  colours.periwinkle,
			  colours.magicMint,
			  colours.newOrleans,
			  colours.apriocotPeach,
			  colours.pink,
			  colours.charlotte,
			  colours.lightGray,
			  colours.darkGreen,
			  colours.darkYellow,
			  colours.red,
			  colours.cerulean,
			  colours.green,
			  colours.darkRed,
			  colours.black,
			  colours.lightRed,
			  colours.lightOrange,
			  colours.mercury,
			  colours.gray,
			  colours.darkPurple,
			],
			action: {
			  active: "rgba(29, 29, 29, 0.7)",
			  hover: "rgba(0, 0, 0, 0.04)",
			  hoverOpacity: "0.04",
			  selected: "rgba(0, 0, 0, 0.08)",
			  selectedOpacity: "0.08",
			  disabled: colours.darkSilverChalice,
			  disabledBackground: colours.darkSilverChalice,
			  disabledOpacity: "0.38",
			  focus: "rgba(0, 0, 0, 0.12)",
			  focusOpacity: "0.12",
			  activatedOpacity: "0.12",
			},
			textColours: {
			  codGray: colours.codGray,
			  tiara: colours.tiara,
			  slateGrayLight: colours.slateGrayLight,
			  subHeader: colours.boulder,
			  athensGrayLight: colours.athensGrayLight,
			  botDateChipText: colours.raven,
			  uploadBadgeText: colours.cobaltBlue,
			},
			colours: {
			  checboxBorder: colours.silverChalice,
			  tableBorderColor: colours.botticelli,
			  disabledBorder: colours.silverChalice,
			  disabledBackground: colours.mercury,
			  filterLabelColor: colours.lightslategray,
			  labelColour: colours.slateGray,
			  secondary: colours.froly,
			  warning: colours.selectiveYellow,
			  sliderRail: colours.periwinkle,
			  silderDisabledRail: colours.concrete,
			  accordionBorder: colours.tiara,
			  disabledBadge: colours.alto,
			  homeButtonBorder: colours.poloBlue,
			  homeButtonShadow: colours.periwinkleGrey,
			  disabledSelectBackground: colours.gallery,
			  toolPanelLineBreak: colours.lightGray,
			  tooltipColor: colours.trout,
			  tooltipArrow: colours.darkSilver,
			  botbuttonBackground: colours.royalViolet,
			  miniButtonBGPrimary: colours.indigo,
			  miniButtonBGSecondary: colours.mediumPurple,
			  botDateChipBackground: colours.alabasterLight,
			  botBlockBackgroud: colours.zirconLight,
			  botLoaderBackgroud: colours.scorpion,
			  boxShadowCard: colours.boxShadowCard,
			  badgeBackground: colours.lavender,
			  highlightBorder: colours.seagull,
			  toggleBackground: colours.madison,
			  dividerColor: colours.chambray,
			},
		  
		primary: {
			main: "rgb(0, 85, 175)",
		},
		secondary: {
			main: "#e0e0e0",
		},
	},
	components: {
		MuiDrawer: {
			styleOverrides: {
				root: {
					flexShrink: "0",
					whiteSpace: "nowrap",
				},
				paper: {
					background: "#091523",
					transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					paddingLeft: "0",
					paddingRight: "0",
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					color: "unset",
					minWidth: "40px",
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {},
				checked: {},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					marginBottom: "20px",
				},
				indicator: {
					// backgroundColor: "#4f677b",
				},
				MuiTab: {
					root: {
						minWidth: "160px",
						padding: "6px 12px",
						fontSize: "0.85rem",
						fontWeight: "600",
						overflow: "hidden",
						position: "relative",
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "capitalize",
					letterSpacing: "0.03rem",
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {},
				paper: {
					maxWidth: "430px",
					borderRadius: "10px 10px 6px 6px",
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					padding: "25px",
				},
			},
		},
		MuiDialogActions: {
			background: "#d3d3d345",
			padding: "15px",
		},
		TextField: {
			styleOverrides: {
				root: {
					maxWidth: "100%",
					minWidth: "100%",
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					cursor: "pointer",
					color: "#5295e1",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					marginLeft: "10px",
					marginRight: "10px",
					height: "34px",
					textTransform: "capitalize",
					textAlign: "left",
					fontSize: "14px",
					lineHeight: "21px",
					fontWeight: "400",
					letterSpacing: "0px",
					padding: "8px 20px",
					// fontSize: "0.9rem",
				}),
				outlined: {
					border: "none",
					"&:hover": {
						border: "none",
						background: "transparent",
					},
				},
			},
		},
		MuiDivider: {
			styleOverrides: {
				root: {
					margin: "6px 0",
					borderColor: "rgba(0, 0, 0, 0.12)",
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					paddingTop: "15px",
					paddingBottom: "15px",
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					pointerEvents:
						ownerState?.disabled === true ? "none" : "cursor",
					color:
						ownerState?.["data-testid"] === "LockOutlinedIcon" ||
						ownerState?.disabled === true
							? "lightSlateGrey"
							: "black",
					fontSize: "16px",
				}),
			},
		},
		MuiButtonBase: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					pointerEvents:
						ownerState?.childIcon && ownerState?.disabled === true
							? "none !important"
							: "cursor",
					color:
						ownerState?.childIcon &&
						(ownerState?.disabled === true
							? "lightSlateGrey !important"
							: "black !important"),
					cursor:
						ownerState?.childIcon && ownerState?.disabled === true
							? "none !important"
							: "default",
					fontSize: "16px",
				}),
			},
		},
	},
});

export default theme;
