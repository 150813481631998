import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useLocation, Link } from "react-router-dom-v5-compat";
import { Button } from "impact-ui";
import { protectedRoutes } from "../../../constants/NewConstants";
import "./Header.scss";

const CustomBreadcrumbs = () => {
	const { pathname } = useLocation();
	const pathnames = pathname.split("/").filter(Boolean);
	return (
		<Breadcrumbs
			className="breadcrumb-container"
			aria-label="breadcrumb"
			sx={{
				backgroundColor: "white",
				fontWeight: "400",
				fontSize: "14px",
				padding: "0px 20px",
			}}>
			{pathnames.map((path) => {
				const currentRoute = protectedRoutes?.find((pRoute) => {
					return pRoute.breadcrumbPath === path;
				});
				const { breadcrumbComponentsPath } = currentRoute || "/landing-screen";
				const breadcrumbComponentList = currentRoute?.title?.split("/") || [];
				{
					return breadcrumbComponentList.map((ele, index) => {
						return (
							<Button variant="url" className="no-padding">
								<Link
									key={breadcrumbComponentsPath[index] + index}
									className={
										index !== breadcrumbComponentList.length - 1
											? "breadcrumb-item"
											: "current-breadcrumb-item"
									}
									to={{
										pathname: breadcrumbComponentsPath[index],
									}}>
									{ele}
								</Link>
							</Button>
						);
					});
				}

				// );
			})}
		</Breadcrumbs>
	);
};

export default CustomBreadcrumbs;
