import moment from "moment";

export const workflowPanelDataValue = [
  {
    id: 1,
    title: "Configuration Details",
    sub_sections: [
      {
        id: 2,
        title: "Basic Details",
        config_id: "9",
        config_type: "moduleConfig",
      },
      {
        id: 3,
        title: "Planning Screen Level",
        config_id: "10",
        config_type: "moduleConfig",
        json_structure: {},
      },
      // {
      //   id: 4,
      //   title: "Table Configuration",
      //   config_id: "10",
      //   config_type: "filterConfig",
      //   json_structure: {},
      // },
    ],
  },
  {
    id: 4,
    title: "KPI Configurator",
    sub_sections: [
      {
        id: 5,
        title: "KPI List",
      },
    ],
  },
  {
    id: 6,
    title: "Screen Palnner",
    sub_sections: [
      {
        id: 7,
        title: "Aggregation Strategy",
        config_id: "5",
        config_type: "tableConfig",
        json_structure: {},
      },
      {
        id: 8,
        title: "Disaggregation Strategy",
        config_id: "13",
        config_type: "userAccessManagementConfig",
        json_structure: {},
      },
    ],
  },
];

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";

export const sideBarFilterModuleConfiguration = [
  {
    id: 1,
    title: "Filter Configuration Details",
    sub_sections: [
      {
        id: 2,
        title: "Inseason filters configurators",
        config_id: "9",
        config_type: "filterConfig",
      },
      {
        id: 3,
        title: "Preseason filters configurators",
        config_id: "10",
        config_type: "filterConfig",
        json_structure: {},
      },
      {
        id: 4,
        title: "Target filters configurators",
        config_id: "10",
        config_type: "filterConfig",
        json_structure: {},
      },
    ],
  },
];

export const outOfTheBoxContainer = [
  {
    id: 1,
    title: "KPI Configurator",
    sub_sections: [
      {
        id: 2,
        title: "KPI List",
        config_id: "8",
        config_type: "filterConfig",
      },
      {
        id: 3,
        title: "Aggregation Strategy",
        config_id: "8",
        config_type: "filterConfig",
      },
      {
        id: 4,
        title: "Dis-Aggregation Strategy",
        config_id: "8",
        config_type: "filterConfig",
      },
    ],
  },
];

export const actionTypesToNotEdit = [
  "link",
  "int",
  "float",
  "percentage",
  "dollar",
  "list",
  "dynamic-list",
  "review_btn",
  "datetime",
];

export const filterConfigPropsValueOutOftheBox = {
  id: 1,
  title: "KPI Configurator",
};

export const filterConfigPropsValue = {
  id: 2,
  title: "Basic Details",
};

///to be customised in future for different clients
export const levelsArray = [
  "l0_name",
  "l1_name",
  "l2_name",
  "l3_name",
  "l4_name",
];

export const calendarConfig = {
  fstDayOfWk: 1, // Monday
  fstMoOfYr: 0, // Jan
};
export const DATE_FORMATS = [
  "DD-MM-YYYY",
  "MM-DD-YYYY",
  "YYYY-MM-DD",
  "MM-DD-YYYY HH:mm:ss",
  "MM-DD-YYYYTHH:mm:ssZ",
  "YYYY-MM-DDTHH:mm:ssZ",
  "YYYY-MM-DD HH:mm:ss",
  "MM-DD-YYYY, HH:mm:ss",
];

export const DEFAULT_ROUNDOFF = 2;
export const IGNORE_ROUNDOFF = ["int"];
export const SPECIAL_CHARACTER_MAPPING = {
  __ia_char_01: "'",
  __ia_char_02: '"',
  __ia_char_03: "/",
  __ia_char_04: "\\",
  __ia_char_05: "`",
  __ia_char_06: "~",
  __ia_char_07: "!",
  __ia_char_08: "@",
  __ia_char_09: "#",
  __ia_char_10: "$",
  __ia_char_11: "%",
  __ia_char_12: "^",
  __ia_char_13: "&",
  __ia_char_14: "*",
  __ia_char_15: "(",
  __ia_char_16: ")",
  __ia_char_19: "=",
  __ia_char_20: "+",
  __ia_char_21: "{",
  __ia_char_22: "}",
  __ia_char_23: "[",
  __ia_char_24: "]",
  __ia_char_25: "|",
  __ia_char_26: ":",
  __ia_char_27: ";",
  __ia_char_28: "<",
  __ia_char_29: ">",
  __ia_char_30: ",",
  __ia_char_31: ".",
  __ia_char_32: "?",
};

export const DEFAULT_START_DATE_MOMENT = moment().add(0, "days");
export const DEFAULT_END_DATE_MOMENT = moment().add(120, "days");
export const DEFAULT_START_DATE = moment()
  .add(0, "days")
  .format(DEFAULT_DATE_FORMAT);
export const DEFAULT_END_DATE = moment()
  .add(120, "days")
  .format(DEFAULT_DATE_FORMAT);
export const DEFAULT_START_DATE_MOMENT_REPORTING = moment().subtract(
  31,
  "days"
);
export const DEFAULT_END_DATE_MOMENT_REPORTING = moment().subtract(1, "days");
export const DEFAULT_START_DATE_MOMENT_DECISION_DASHBOARD = moment().subtract(
  14,
  "days"
);

export const NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER = 1;

export const singleSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "32px",
    minHeight: "32px",
    fontSize: "0.8rem",
    border: "1px solid #cccccc",
    top: "2px",
    cursor: "pointer",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #cccccc",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "32px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    color: "transparent",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "32px",
    // minHeight: "32px",
    padding: "6px",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  indicatorContainer: (provided, state) => ({
    ...provided,
    padding: "0px 8px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "2px 4px 2px",
    fontSize: "0.8rem",
  }),
  menuList: (provided, state) => ({
    ...provided,
    fontSize: "0.8rem",
    padding: 0,
  }),
};

export const STRATEGY_STATUS_FILTER_DEFAULTS = [0, 5, 1, 2, 3, 4];

export const STRATEGY_STATUS = [
  {
    label: "Draft/Copied",
    value: 0,
  },
  {
    label: "Pending Review",
    value: 1,
  },
  {
    label: "Finalized",
    value: 2,
  },
  {
    label: "Archived",
    value: 6,
  },
];

export const VALID_OFFER_TYPES = {
  percent_off: "% Off",
  extra_amount_off: "$ Off",
  fixed_price: "PP",
  bxgy: "BXGY",
  bundle_offer: "Bundle Offer",
  reg_price: "Reg Price",
  reg_price_cv: "Reg Price CV",
  bxgy_$: "Bundle Offer Buy",
  bmsm: "BMSM",
};

export const DISCOUNT_LEVELS = {
  overall: -200,
  product_h1: 1,
  product_h2: 2,
  product_h3: 3,
  product_h4: 4,
  product_h5: 5,
};

export const PRODUCT_HIERARCHY = [
  {
    label: "Overall",
    value: DISCOUNT_LEVELS.overall,
    hierarchy: -200,
  },
  {
    label: "Division",
    value: DISCOUNT_LEVELS["product_h1"],
    hierarchy: "product_h1",
  },
  {
    label: "Department",
    value: DISCOUNT_LEVELS["product_h2"],
    hierarchy: "product_h2",
  },
  {
    label: "Class",
    value: DISCOUNT_LEVELS["product_h3"],
    hierarchy: "product_h3",
  },
  {
    label: "Sub Class",
    value: DISCOUNT_LEVELS["product_h4"],
    hierarchy: "product_h4",
  },
  {
    label: "SKU",
    value: DISCOUNT_LEVELS["product_h5"],
    hierarchy: "product_h5",
  },
];

export const ACTION_MAPPING = {
  1: "create_promo",
  2: "edit_promo",
  3: "delete_promo",
  4: "download",
  5: "upload",
  6: "view_promo",
  7: "approve_promo",
  8: "finalize_promo",
  9: "create_event",
  10: "edit_event",
  11: "delete_event",
  12: "lock_event",
  13: "view_event",
  14: "download_marketing_report",
  15: "download_signage_report",
  16: "download_sap_report",
};

// export const ROLE_ACTION_MAPPING = {
// 	1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
// 	2: [4, 6, 9, 10, 11, 12, 13],
// 	3: [1, 2, 3, 4, 5, 6, 7, 8, 13],
// 	4: [4, 6, 13],
// };

export const SUPPORT = "/support";

export const CONFLICT_TYPES = {
  0: "IN FINALIZED",
  1: "IN REQUEST",
};

export const NUMERIC_FIELDS = ["int", "IntegerField"];

export const textFilterOptions = [
  { label: "Contains", value: "contains" },
  { label: "Equals", value: "equals" },
];

export const END_DATE = "2050-12-31";
export const START_DATE = "2000-01-01";
export const SKU_STORE_STATUS_START_DATE = "2022-12-01";

export const STORE_INVENTORY_LINK_COLUMNS_RIGHT_ALIGNED = [
  "stock_out",
  "stockout",
  "shortfall",
  "normal",
  "excess",
];

export const columnActionTypes = [
  "bool",
  "ToogleField",
  "edit_icon",
  "delete_icon",
  "chart_icon",
  "add_icon",
  "download_icon",
  "info_icon",
];

export const STORE_HIERARCHY_MAPPING = {
  store_h0: "Country",
  store_h1: "DC",
  store_h2: "Region",
  store_h3: "State",
  store_h4: "District",
  store_h5: "City",
  store_h6: "Location",
};

export const rule_flexibility_type_id = {
  Flexible: 0,
  Inflexible: 1,
};

export const simulation_data_formatter_mapping = {
  Units: "toUnit",
  "Revenue $": "toDollar",
  "Margin $": "toDollar",
  "Margin %": "toPercentage",
  "AUR $": "toDollarWithDecimal",
  "AUM $": "toDollarWithDecimal",
  "ST %": "toPercentage",
  "Markdown $": "toDollar",
};

export const columnsForIaTable = {
  bl_override: {
    label: "BL Override",
    id: "bl_override",
  },
  ia_reco: {
    label: "IA Recom",
    id: "ia_reco",
  },
};

export const lockColumnsKey = ["product_level_value"];

export const cellsWithLockAndInput = ["bl_override", "ia_reco"];

export const optimizationType = {
  ia_optimization: 0,
  partial_optimization: 1,
  no_optimization: 2,
};

export const disbleColumnMenu = ["price_point"];
export const DEFAULT_BASIC_DETAILS_FORM_DATA = {
  config_name: "",
  config_description: "",
  attributeId: -1,
};

export const VALIDATION = {
  config_name: "Please enter config name",
  generic_msg: "Please enter valid data",
};

export const sideBarDashboardModuleConfiguration = [
  {
    id: 1,
    title: "Dashboard Configuration Details",
    sub_sections: [
      {
        id: 2,
        title: "Inseason Dashboard Configurator",
        config_id: "9",
        config_type: "dashboardConfig",
      },
      {
        id: 3,
        title: "Preseason Dashboard Configurator",
        config_id: "10",
        config_type: "dashboardConfig",
        json_structure: {},
      },
      {
        id: 4,
        title: "Target Dashboard Configurator",
        config_id: "10",
        config_type: "dashboardConfig",
        json_structure: {},
      },
    ],
  },
];

export const FILTER_CONFIGURATOR_TABS = [
  {
    id: "create_plan_filter",
    value: "Create Plan Filter",
    label: "Create Plan Filter",
  },
  {
    id: "master_plan",
    value: "Master Plan",
    label: "Master Plan",
  },
  {
    id: "quick_filters",
    value: "Quick Filter",
    label: "Quick Filter",
  },
]